import * as React from "react"
import { Router } from "@reach/router"

import Layout from "../components/Layout"

const SomeSubPage = props => {
  return <div>Hi from SubPage with id: {props.id}</div>
}

const App = () => (
  <Layout>
    <Router>
      <SomeSubPage path="/app/:id" />
    </Router>
  </Layout>
)

export default App